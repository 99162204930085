<template>
  <div class="like-dislike-widget-container" ref="wraper" :style="setStyles">
    <button
      @click="onClick(idx)"
      class="like-dislike-item"
      v-for="(s, idx) of likeDislikeRespond"
      :key="idx"
    >
      <span>{{ s.name }}</span>
      <img
        class="btn-icons like"
        v-show="s.value === likeName"
        :src="likeIcon"
      />
      <img
        class="btn-icons dislike"
        v-show="s.value === dislikeName"
        :src="dislikeIcon"
      />
    </button>
  </div>
</template>

<script>
import fetchFont from "@/utils/fetchFont";
import setStyles from "@/utils/setStyles";
import { mapGetters } from "vuex";
import { updateRespond } from "@/utils/clickAction";

export default {
  name: "SvLikeDislikeWidget",
  props: ["widgetIdx"],
  computed: {
    ...mapGetters(["interaction", "step", "respond", "activeStep"]),
    widget() {
      return this.activeStep?.objects?.[this.widgetIdx];
    },
    dislikeName() {
      return this.widget.configs.dislikeName;
    },
    likeName() {
      return this.widget.configs.likeName;
    },
    likeIcon() {
      return this.widget.configs.likeIcon;
    },
    dislikeIcon() {
      return this.widget.configs.dislikeIcon;
    },
    setStyles() {
      return {
        ...setStyles(this.widget),
        height: this.widget.height + "px",
        fontWeight: this.widget.configs.fontWeight,
        "--button-text-color": this.widget.configs.textColor,
        color: this.widget.configs.textColor,
        "--button-font-family": this.widget.configs.fontFamily,
        "--button-background-color": this.widget.configs.textBackgroundColor,
        fontStyle: this.widget.configs.fontStyle,
        "--button-font-size": this.widget.configs.fontSize + "px",
        "text-decoration": this.widget.configs.underline ? "underline" : "none"
      };
    }
  },
  data() {
    return {
      likeDislikeRespond: [],
      activeLikeDislikeIdx: ""
    };
  },
  created() {
    this.likeDislikeRespond = this.widget.configs.likeDislikeList.map(s => ({
      name: s,
      value: ""
    }));
  },
  mounted() {
    const fontFamily = this.widget.configs.fontFamily.replace(/\.(ttf|otf)/g, "");
    const fontLink = this.widget.configs.fontLink

    if (fontFamily)
      fetchFont(fontLink, fontFamily).then(() => {
        this.$refs.wraper.style.fontFamily = fontFamily;
        this.$emit("hideLoader");
      });
  },
  methods: {
    onSpecify(value) {
      this.likeDislikeRespond[this.activeLikeDislikeIdx].value = value;
      this.$store.dispatch("showPopup", { clean: true });
      this.$store.dispatch("updateRespond", {
        idx: this.widgetIdx,
        step: this.step,
        data: {
          widgetType: "like-dislike",
          widgetName: "like-dislike",
          actionType: "like-dislike",
          actionData: this.likeDislikeRespond
        }
      });
      updateRespond();
    },
    onClick(idx) {
      this.activeLikeDislikeIdx = idx;
      this.$store.dispatch("showPopup", {
        visible: true,
        type: "likeDislikePopup",
        data: {
          callback: this.onSpecify,
          likeIcon: this.likeIcon,
          dislikeIcon: this.dislikeIcon,
          likeName: this.likeName,
          dislikeName: this.dislikeName,
          textBackgroundColor: this.widget.configs.textBackgroundColor,
          textColor: this.widget.configs.textColor,
          textLikeDislike: this.likeDislikeRespond[this.activeLikeDislikeIdx],
          fontFamily: this.widget.configs.fontFamily
        }
      });
    }
  }
};
</script>

<style scoped>
.like-dislike-widget-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}
.like-dislike-widget-container button {
  background: var(--button-background-color);
  color: var(--button-text-color);
  font-family: var(--button-font-family);
  font-size: var(--button-font-size);
  border: none;
  padding: 0 15px;
  max-width: 300px;
  cursor: pointer;
  height: 30px;
  line-height: 30px;
}
.like-dislike-widget-container button span {
  max-width: 270px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.btn-icons {
  display: flex;
  align-items: center;
  margin-left: 10px;
  max-width: 20px;
  max-height: 20px;
}
.like-dislike-item {
  display: flex;
  align-items: center;
}
.like {
  fill: var(--like-color);
}
.dislike {
  fill: var(--dislike-color);
}
</style>
